<template>
  <div>
    <h1>okkkkkkkk</h1>
  </div>
</template>
<script>
export default {
  name: "Wishlist",
  components: {},

  data() {
    return {};
  },
};
</script>
<style lang="scss">
//@import "@/assets/frontend/style/checkout.scss";
</style>
